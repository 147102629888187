<template>
  <v-container fluid style="width: 100%; height: 100%">
    <div style="height: 1000px; z-index: 0 ">
      <l-map
          style="z-index: 0; height: 100%"
          :zoom="zoom"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker v-if="sensors" v-for="sensor in sensors" :lat-lng="[sensor.lat, sensor.lng]"
                  :options="{title: sensor.name}">
          <l-popup>
            {{ sensor.name }}
          </l-popup>
        </l-marker>
        <l-marker v-if="signs" v-for="sign in signs" :lat-lng="[sign.lat, sign.lng]" :options="{title: sign.name}">
        </l-marker>
      </l-map>
    </div>
  </v-container>

</template>
<script>
import analyticOneCard from "@/components/card/AnalyticCardVersionOne";
import analyticTwoCard from "@/components/card/AnalyticCardVersionTwo";

import MapPreviewSign from "@/components/MapPreviewSign";

import {
  analyticOne,
  analyticTwo,
  analyticThree,
  analyticFour,
  analyticFive,
} from "/examples/src/data/analytic2";

import {LMap, LMarker, LPopup, LTileLayer, LIcon} from "vue2-leaflet";
import {generateDayWiseTimeSeries} from "/examples/src/data/apexDataSeries";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard",
  },
  components: {
    "analytic-one-card": analyticOneCard,
    "analytic-two-card": analyticTwoCard,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    MapPreviewSign
  },
  computed: {
    siteOccupancy() {
      this.$store.dispatch('sites/find', {});
      return this.$store.getters['sites/list'];
    }
  },
  created() {
  },
  mounted() {
    this.getSensors();
    this.getSigns();
    this.$store.dispatch('car-parks/find', {});
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 6,
      center: [51.48819, -0.31524],
      bounds: null,
      sensors: [],
      signs: [],
      analyticOne,
      analyticTwo,
      analyticThree,
      analyticFour,
      analyticFive,
      brushLineChart: {
        series: [{
          name: "Day Max Occupancy",
          data: generateDayWiseTimeSeries(new Date('6 Mar 2021').getTime(), 150, {
            min: 20,
            max: 1100,
          }),
        }],
        chartOptionsArea: {
          chart: {
            id: 'chartArea',
            toolbar: {
              autoSelected: 'pan',
              show: false,
            },
          },
          colors: ['#546E7A'],
          stroke: {
            width: 3,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 1,
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: 'datetime',
          },
        },
        chartOptionsBrush: {
          chart: {
            id: 'chartBrush',
            brush: {
              target: 'chartArea',
              enabled: true,
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date('19 Jun 2017').getTime(),
                max: new Date('14 Aug 2017').getTime(),
              },
            },
          },
          colors: ['#008FFB'],
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.91,
              opacityTo: 0.1,
            },
          },
          xaxis: {
            type: 'datetime',
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            tickAmount: 2,
          },
        },

      }
    };
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    getSigns() {
      this.$store.dispatch('signs/find', {}).then((result) => {
            this.signs = result.data;
          }
      );
    },
    getSensors() {
      this.$store.dispatch('sensors/find', {}).then((result) => {
            this.sensors = result.data;
          }
      );
    },
  }
};
</script>

<style>
.signDiv {
  background-color: black;
  width: 300px;
  height: 240px;
  border: 10px grey;
  border-radius: 5px;
  padding: 10px;
}

.signText {
  font-family: 'DotMatrix Regular';
  color: #FFDA5F;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: bold;
}

.signRed {
  color: red;
}

.signGreen {
  color: greenyellow;
}

@font-face {
  font-family: 'DotMatrix Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DotMatrix Regular'), url('/dotmatrix.woff') format('woff');
}

</style>
