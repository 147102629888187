<template>
  <div class="signWrapper">
    <div class="signDiv">
      <div v-if="carPark">
        <img src="/gskparking-banner.png">
        <p class="signText">
          {{ carPark.name }}<br>
          Capacity: {{ carPark.capacity }}<br>
          Occupancy: {{ carPark.occupancy }}
        </p>
      </div>
      <div v-else-if="carParks">
        <img src="/gskparking-banner.png">
        <div v-for="carPark in carParks" style="padding-top: 10px">
          <div style="float: left; padding:3px;" class="signText">{{ carPark.name }}</div>
          <div style="float: right;" class="signText">{{ carPark.occupancy }}</div>
        </div>
      </div>
    </div>
    <div class="signCaption">{{sign.name}}</div>
  </div>
</template>

<script>
export default {
  name: "MapPreviewSign",
  props: ['sign'],
  data() {
    return {
      carPark: null,
      carParks: null,
    }
  },
  mounted() {
    if (this.sign.signTypeId === 2) {
      this.$store.dispatch('car-parks/get', this.sign.carParkId).then((result) => {
            this.carPark = result;
          }
      );
    } else {
      this.$store.dispatch('car-parks/find').then((result) => {
            this.carParks = result.data;
          }
      );
    }

  },
}
</script>

<style>
@font-face {
  font-family: 'DotMatrix Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DotMatrix Regular'), url('/dotmatrix.woff') format('woff');
}

.signWrapper {
  text-align: center;
}

.signCaption {
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  background-color: rgba(0,0,0,0.4);
  border: black dot-dot-dash 4px;
}
.signDiv {
  text-align: left;
  background-color: black;
  width: 300px;
  height: 240px;
  border: 10px grey;
  border-radius: 5px;
  padding: 10px;
}

.signText {
  font-family: 'DotMatrix Regular';
  color: #FFDA5F;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: bold;
}

.signRed {
  color: red;
}

.signGreen {
  color: greenyellow;
}
</style>
